import { useEffect, useState } from "react";
import { useWallet } from '@solana/wallet-adapter-react'
import { useHistory, useParams } from "react-router-dom"
import Header from "../Header/Header"
import "./NFTs.css"
import "../Create/Create.css"


const NFT = (props) => {

  const history = useHistory()

  return (
    <div className='header-page header-page-white header-page-centered'>
      <Header holder/>
      <div className='no-nfts'>
        <div className='none-detected'>
          NO TAMPERPROOF NFTs DETECTED
        </div>

        <div className='no-nfts-subtext'>
          <span 
            onClick={()=>history.push("/create/create")}
            className='no-nfts-subtext-bold'
          >
            Create a tpNFT with our demo 
          </span>

        </div>
    
      </div>
    </div> 
  )
}

export default NFT
