import "./Header.css"
import { useWallet } from '@solana/wallet-adapter-react'
import { useHistory } from 'react-router-dom'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'

const Header = (props) => {

  const { publicKey } = useWallet()
  const history = useHistory()

  const publicKeySliced = () => {
    if (!publicKey) return ""
    let str = publicKey.toString()
    if (!publicKey) return ""
    let start = str.substr(0,4)
    let end = str.substr(str.length-4, str.length)
    return `${start}...${end}`
  }

  return (
    <div 
      className={`header ${props.create ? 'black-header'  :'white-header'} `}
    >
      <img 
        className='header-logo'
        alt='logo'
        style={{cursor:'pointer'}}
        onClick={()=>window.location.href='https://www.tamperproof.xyz/'}
        src={
          props.create ?
            'https://cdn.hngr.co/tamperproof/tamperproof_logo_white.png' :
            'https://cdn.hngr.co/tamperproof/tamperproof_logo_black.png'
        }
      />
      <div className='header-right'>
        <div 
          className='header-link'
          onClick={()=>{
            let path = props.create ? '/holder/landing' : '/create/landing'
            history.push(path)
          }}
        >
          {props.create ? 'Unseal Tamperproof NFT' : 'Create Tamperproof NFT'}
        </div>

        <WalletMultiButton>
          {publicKeySliced()}
        </WalletMultiButton>

      </div>

    </div>
  )
}

export default Header
