import { PublicKey, PublicKeyInitData } from "@solana/web3.js";
import * as SPLToken from "@solana/spl-token";
import * as MPLTokenMetadata from "@metaplex-foundation/mpl-token-metadata";

export class PDA extends PublicKey {
  public readonly bump: number;

  protected constructor(value: PublicKeyInitData, bump: number) {
    super(value);
    this.bump = bump;
  }

  protected static _cache: Map<string, PDA> = new Map();

  /**
   * Finds PDA for given program ID and seeds.
   */
  public static find(programId: PublicKey, ...seeds: Uint8Array[]): PDA {
    const cacheKey = `${programId.toString()}:${seeds.map((seed) =>
      seed.toString()
    )}`;
    const cached = this._cache.get(cacheKey);
    if (cached) return cached;

    const [publicKey, bump] = PublicKey.findProgramAddressSync(
      seeds,
      programId
    );
    const result = new PDA(publicKey, bump);
    this._cache.set(cacheKey, result);

    return result;
  }

  /**
   * Returns associated token account address.
   */
  public static token(
    mint: PublicKey,
    owner: PublicKey,
    tokenProgramId?: PublicKey
  ): PDA {
    tokenProgramId ??= SPLToken.TOKEN_PROGRAM_ID;
    return this.find(
      SPLToken.ASSOCIATED_TOKEN_PROGRAM_ID,
      owner.toBuffer(),
      tokenProgramId.toBuffer(),
      mint.toBuffer()
    );
  }

  /**
   * Returns Metaplex metadata account address.
   */
  public static tokenMetadata(mint: PublicKey): PDA {
    return this.find(
      MPLTokenMetadata.PROGRAM_ID,
      Buffer.from("metadata", "utf8"),
      MPLTokenMetadata.PROGRAM_ID.toBuffer(),
      mint.toBuffer()
    );
  }

  /**
   * Returns Metaplex NFT master edition account address.
   */
  public static masterEdition(mint: PublicKey): PDA {
    return this.find(
      MPLTokenMetadata.PROGRAM_ID,
      Buffer.from("metadata", "utf8"),
      MPLTokenMetadata.PROGRAM_ID.toBuffer(),
      mint.toBuffer(),
      Buffer.from("edition", "utf8")
    );
  }
}
