import { useEffect, useState } from "react";
import { useWallet } from '@solana/wallet-adapter-react'
import { useHistory, useLocation } from "react-router-dom"
import Header from "../Header/Header"
import "./Create.css"


const Success = props => {

  const history = useHistory()
  const location = useLocation()
  const { publicKey } = useWallet()

  if(!location.state?.metadata?.name){
    history.replace("/")
  }

  useEffect(() => {
    if(publicKey){
      // yo
    }
  }, [publicKey]);

  return (
    <>
      <div className='header-page header-page-black header-page-centered'>
        <Header create/>
        <div className='create-center'>
          <div className='create-center-left'>
            <div className='sealed-tag'>
              <div> SEALED </div>
            </div>

            <div className='success-nft-title'>
              {location.state.metadata.name}
            </div>

            <div className='secret-safe-with-us'>
              YOUR SECRET IS <span className='green'>SAFE</span> WITH US 🤫
            </div>

            <div className='secret-safe-with-us-subtext'>
              This tpNFT contains a secret code written by <span className='green'>2JFn...2mj2</span>
            </div>

            <div className='success-button-row'>
              <a
                target='_blank'
                href={`https://solana.fm/address/${location.state.mint}?cluster=mainnet-solanafmbeta`}
                className='sol-explorer-button'
              >
               SolanaFM 
              </a>

              <div 
                onClick={() => history.push("/holder/landing")}
                className='unseal-demo-button'
              >
                UNSEAL DEMO
              </div>
            </div>

            <div className='also-view'>
              Also view our other collections on OpenSea or MagicEden.
            </div>



          </div>
 
          <div className='cube-box'>
            <img
              alt='cube'
              src='https://cdn.hngr.co/tamperproof/solcube.gif'
              className='cube-box-img'
            />
          </div>
       </div>
      
      </div>
    </>
  )
}

export default Success 
