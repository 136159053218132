import "./modal.css"
import ModalNFT from "./ModalNFT"

const ErrorModal = ( {mint} ) => {

  return (
    <div className='modal-background'>
      <div className='modal-container'>
        <div className='modal-top-row'>
          <div className='modal-top-row-left'>
            <div 
              className='modal-top-row-left-top-text'
              style={{color:'#FF4949'}}
            >
              ERROR
            </div>
            <div 
              className='modal-top-row-left-bottom-text'
              style={{color:'#FF4949'}}
            >
              SOMETHING WENT WRONG
            </div>
          </div>
          <img
            alt='spinner'
            src='https://cdn.hngr.co/tamperproof/error.png'
            className='modal-top-row-image'
          />
        </div> { /*modal-top-row*/ }
        <ModalNFT
          img='https://cdn.hngr.co/tamperproof/solcube.gif'
          name='TAMPERPROOF #2131'
        />
        
        <div 
          style={{marginTop:'28px'}}
          className='sign-reveal-button'
          onClick={()=>mint()}
        >
          <div>RETRY MINTING</div>
        </div>

        

      </div>{ /*modal-container*/ }
    </div>
  )

}

export default ErrorModal
