import { useEffect, useState } from "react";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react'
import { useHistory } from "react-router-dom"
import "../css/landing.css"

import postToSlack from "../postToSlack"


const Landing = props => {

  const history = useHistory()
  const { publicKey } = useWallet()
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    if(publicKey){
      postToSlack(`${publicKey.toString()} connected wallet on holder side`)
      history.push("/holder/NFTs")
    }
  }, [publicKey]);

  useEffect(() => {
    setTimeout(()=>setLoading(false), 3000)
  }, []);

  return (
    <div className='landing-container landing-container-white'>
      <div className='landing-container-center-content'>
        {loading && (
          <>
            <img 
              src='https://cdn.hngr.co/tamperproof/tamperproof_logo_black.png'
              alt='logo' 
              className='landing-logo'
            />
            <div className='landing-connect-button-container'>
              <img 
                src='https://cdn.hngr.co/tamperproof/landingspinner.gif' 
                className='landing-spinner'
                alt='spinner'
              />
            </div>
          </>
        )}

        {!loading && (
          <>  
            <div className='landing-center-header-text'>
              Unseal a tpNFT
            </div>

            <WalletMultiButton>
              <div 
                className='landing-connect-button-container'
                style={{
                  cursor:'pointer',
                  marginTop:'32px',
                  marginBottom:'32px'
                }}
              >
                <div className='connect-button-text' >CONNECT WALLET</div>
              </div>
            </WalletMultiButton>

            <div className='landing-subtext'>
              Connect your wallet to check for Tamperproof NFTs to unseal.
              <br/><br/>
              To create and seal a secret message in a NFT: &nbsp;
              <span 
                className='green'
                style={{textDecoration:'underline'}}
                onClick={()=>history.push("/create/landing")}
              >
                Create one in our demo
              </span>
            </div>
          </>
        )}

      </div>
    </div>
  )
}

export default Landing 
