const ModalNFT = ({img, name}) =>  (
  <div className='modal-nft-container'>
    <div className='modal-nft-image-box'>
      <img src={img} alt='cube' />
    </div>
    <div className='modal-nft-name'>
      {name}
    </div>
  </div>
)
export default ModalNFT
