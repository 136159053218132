import "../Create/modal.css"
import ModalNFT from "../Create/ModalNFT"

const UnsealingModal = ( props ) => {

  return (
    <div className='modal-background'>
      <div className='modal-container modal-container-white'>
        <div className='modal-top-row'>
          <div className='modal-top-row-left'>
            <div 
              className='modal-top-row-left-top-text'
              style={{color:'#0A0909'}}
            >
              UNSEALING...
            </div>
            <div 
              style={{color:'#757575'}}
              className='modal-top-row-left-bottom-text'
            >
              &lt;ENABLING REVEAL&gt;
            </div>
          </div>
          <img
            alt='spinner'
            src='https://cdn.hngr.co/tamperproof/landingspinner.gif'
            className='modal-top-row-image'
          />
        </div> { /*modal-top-row*/ }
        <ModalNFT
          img={props.token?.metadata._json.image}
          name={props.token?.metadata._json.name}
        />
        <div className='modal-subtext' style={{color:'#8D8D8D'}}>
          This may take a few minutes...
        </div>
      </div>{ /*modal-container*/ }
    </div>
  )

}

export default UnsealingModal 
