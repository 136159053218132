import { unpackAccount } from "@solana/spl-token";
import {
  BaseAccount,
  TokenAccount,
  TransactionResult,
  AccountEvent,
  PDA,
  AccountEventCallback,
} from "@captainxyz/solana-core";

import { TamperproofMetadata, isTamperproofMetadata } from "./metadata";
import { SealedSecret, getState } from "./secrets";
import { decodePublicKey } from "./codec";

export interface TamperproofOracle extends TokenAccount {
  /**
   * TamperproofOracle extension identifier, is used for type checking.
   */
  readonly extTamperproofOracle: true;

  /**
   * Metadata account.
   */
  readonly metadata: TamperproofMetadata;

  /**
   * Returns true if the secret is unsealed.
   */
  readonly isUnsealed: boolean;

  /**
   * Account event that is triggered before unsealing.
   */
  readonly beforeUnseal: AccountEvent;

  /**
   * Account event that is triggered after unsealing.
   */
  readonly afterUnseal: AccountEvent;

  /**
   * Unseals the secret.
   *
   * @param transactionSignature Transaction signature to check for unsealing request.
   * @param holderKey Public key of the holder.
   */
  tryUnseal(
    transactionSignature?: string,
    holderKey?: Uint8Array
  ): Promise<TransactionResult | null>;
}

/**
 * Narrows down type checking to {@link TamperproofOracle}.
 */
export function isTamperproofOracle(
  account: any
): account is TamperproofOracle {
  return (
    account instanceof TokenAccount &&
    account.hasOwnProperty("extTamperproofOracle")
  );
}

export type OracleCallback = (account: TamperproofOracle) => Promise<void>;
export const beforeUnseal: Set<OracleCallback> = new Set();
export const afterUnseal: Set<OracleCallback> = new Set();

BaseAccount.extensions.add({
  isApplicable(account: BaseAccount): boolean {
    return (
      account instanceof TokenAccount &&
      isTamperproofMetadata(account.metadata) &&
      account.metadata.canUpdate
    );
  },
  async onApply(account: TamperproofOracle): Promise<void> {
    account.onDeactivate.subscribe(() =>
      Promise.all([
        account.beforeUnseal.deactivate(),
        account.afterUnseal.deactivate(),
      ])
    );

    console.log("oracle oracle oracle!");

    if (!account.isUnsealed) {
      for (let callback of beforeUnseal) {
        account.beforeUnseal.subscribe(callback as AccountEventCallback);
      }
      for (let callback of afterUnseal) {
        account.afterUnseal.subscribe(callback as AccountEventCallback);
      }

      const result = await account.tryUnseal();
      console.log("unseal result", result);

      if (!result) {
        const listenerId = account.operator.connection.onLogs(
          account.metadata.address,
          async (logs) => {
            const holderKey = extractHolderKey(...logs.logs);
            if (holderKey) await account.tryUnseal(logs.signature, holderKey);
          }
        );
        account.onDeactivate.subscribe(() =>
          account.operator.connection.removeAccountChangeListener(listenerId)
        );
      }
    }
  },
  properties: {
    extTamperproofOracle: {
      value: true,
    },
    beforeUnseal: {
      value: () => new AccountEvent(),
    },
    afterUnseal: {
      value: () => new AccountEvent(),
    },
    isUnsealed: {
      get(this: TamperproofOracle): boolean {
        return getState(this.metadata.json.secret) === "unsealed";
      },
    },
  },
  methods: {
    async tryUnseal(
      this: TamperproofOracle,
      transactionSignature?: string,
      holderKey?: Uint8Array
    ): Promise<TransactionResult | null> {
      let result: TransactionResult | null = null;

      console.log("inside try unseal");

      if (this.isUnsealed) return result;

      console.log("inside try unseal2");

      if (!transactionSignature) {
        const transactionsInfo =
          await this.operator.connection.getSignaturesForAddress(
            this.metadata.address
          );
        for (let transactionInfo of transactionsInfo) {
          holderKey = extractHolderKey(transactionInfo.memo);
          if (holderKey) {
            transactionSignature = transactionInfo.signature;
            break;
          }
        }
      }
      if (!transactionSignature) {
        console.log("returning result from no txn signature");
        return result;
      }

      const transaction = await this.operator.connection.getParsedTransaction(
        transactionSignature,
        { maxSupportedTransactionVersion: 0 }
      );
      if (!transaction) {
        console.log("returning result from no transaction");
        return result;
      }

      if (!holderKey) {
        holderKey = extractHolderKey(...(transaction.meta?.logMessages ?? []));
      }
      if (!holderKey) {
        console.log("returning result from no holder key");
        return result;
      }

      for (let account of transaction.transaction.message.accountKeys) {
        if (account.signer) {
          const holderAddress = PDA.token(
            this.mint.address,
            account.pubkey,
            this.programId
          );
          const holderAccountInfo =
            await this.operator.connection.getAccountInfo(holderAddress);
          if (!holderAccountInfo) {
            console.log("returning result from no holder account info");
            return result;
          }
          const holderAccount = unpackAccount(
            holderAddress,
            holderAccountInfo,
            this.programId
          );
          if (holderAccount.amount != 1n) {
            console.log("returning result from no holder account amount not 1");
            return result;
          }
          break;
        }
      }

      await this.beforeUnseal.trigger(this);

      // If there are several instances of TamperproofOracle run concurrently,
      // each instance **must** acquire global lock within `beforeUnseal`
      // event handler.
      //
      // Therefore, we should test if the secret still sealed
      // and the unseal request has not been handled by another instance.
      if (!this.isUnsealed) {
        console.log("inside last if statement in try unseal");
        const hiddenSecret = SealedSecret.decode(this.metadata.json.secret);
        const revealedSecret = await hiddenSecret.unseal(
          this.operator.identity!,
          holderKey
        );
        result = await this.metadata.update({
          ...hiddenSecret.onUnseal,
          secret: revealedSecret.encode(),
        });
      }
      await this.afterUnseal.trigger(this);

      console.log("returning result bottom", result);
      return result;
    },
  },
});

function extractHolderKey(...logs: any[]): Uint8Array | undefined {
  for (let log of logs) {
    if (typeof log != "string") continue;
    const match = /Unseal secret using ([0-9a-f]+)/.exec(log);
    try {
      return decodePublicKey(match?.[1]);
    } catch {}
  }
  return undefined;
}
