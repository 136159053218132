import { PublicKey, AccountInfo, Keypair } from "@solana/web3.js";
import * as SPLToken from "@solana/spl-token";

import { Operator, TransactionBlock, TransactionResult } from "../operator";
import { Currency, Amount } from "../currency";
import { Transactions } from "../transactions";
import { PDA } from "../pda";
import { BaseAccount, SendInstruction } from "./base";
import { MintAccount } from "./mint";
import { MetadataAccount, JSONMetadata } from "./metadata";

/**
 * @group Accounts
 */
export class TokenAccount extends BaseAccount {
  /**
   * Owner's address of the account.
   */
  public readonly owner: PublicKey;

  /**
   * Assets balance on the account.
   */
  public readonly balance: Amount;

  public readonly programId: PublicKey;
  public readonly mint: MintAccount;
  public readonly metadata: MetadataAccount;

  protected constructor(params: {
    operator: Operator;
    address: PublicKey;
    lamports: number | bigint;
    owner: PublicKey;
    programId: PublicKey;
    balance: Amount;
    mint: MintAccount;
  }) {
    super(params);
    this.owner = params.owner;
    this.balance = params.balance;
    this.programId = params.programId;
    this.mint = params.mint;
    this.metadata = params.mint.metadata;

    const address = this.address.toString();
    this.mint.tokens.set(address, this);
    this.onDeactivate.subscribe(async () => {
      this.mint.tokens.delete(address);
      if (!this.mint.tokens.size) await this.mint.deactivate();
    });
  }

  /**
   * Finds all token accounts of given owner.
   *
   * If `owner` is omitted, operator.{@link Operator.publicKey} will be used.
   */
  public static async find(
    operator: Operator,
    owner?: PublicKey
  ): Promise<TokenAccount[]> {
    if (!owner) {
      if (!operator.identity) throw new Error("Undefined owner address");
      owner = operator.identity.publicKey;
    }
    const programIds = [
      SPLToken.TOKEN_PROGRAM_ID,
      SPLToken.TOKEN_2022_PROGRAM_ID,
    ];
    const accounts = [];
    for (let programId of programIds) {
      const { value } = await operator.connection.getTokenAccountsByOwner(
        owner,
        {
          programId,
        }
      );
      for (let { pubkey, account } of value) {
        try {
          accounts.push(await TokenAccount.init(operator, pubkey, account));
        } catch (err) {
          console.log("error initing token account", err);
        }
      }
    }
    return accounts;
  }

  /**
   * Creates new {@link MintAccount | mint },
   * {@link MetadataAccount | metadata},
   * and token accounts from given {@link Currency} or {@link Amount}.
   *
   * If amount is given,
   * the method performs intial mint to the new token account.
   */
  public static async create(
    operator: Operator,
    params: {
      currency?: Currency;
      amount?: Amount;
      json?: JSONMetadata;
      programId?: PublicKey;
      nft?: boolean | "token" | "collection";
      collectionMint?: PublicKey;
    }
  ): Promise<[TokenAccount, TransactionResult]> {
    if (!operator.identity) throw new Error("Operator is in read-only mode");

    let amount: Amount | null;
    let currency: Currency;
    if (params.amount) {
      amount = params.amount;
      currency = amount.currency;
    } else if (params.currency) {
      amount = null;
      currency = params.currency;
    } else {
      throw new Error("Either amount or currency should be provided");
    }

    if (params.nft) {
      amount ??= currency.amountFromQty(1);
      if (amount.qty != 1n) throw new Error("Invalid amount for NFT");
      if (currency.decimals != 0) throw new Error("Invalid decimals for NFT");
    }

    const jsonURI = await operator.storage.upload({
      json: {
        ...params.json,
        name: currency.name,
        symbol: currency.symbol,
        image: currency.logoURI,
      },
    });
    const programId = params.programId ?? SPLToken.TOKEN_PROGRAM_ID;

    const mintKeypair = Keypair.generate();
    const tokenAddress = PDA.token(
      mintKeypair.publicKey,
      operator.identity.publicKey,
      programId
    );
    const metadataAddress = PDA.tokenMetadata(mintKeypair.publicKey);
    const mintRentExcempt = await SPLToken.getMinimumBalanceForRentExemptMint(
      operator.connection
    );
    const transactions = [
      Transactions.createMintAccount({
        payer: operator.identity.publicKey,
        keypair: mintKeypair,
        lamports: mintRentExcempt,
        decimals: currency.decimals,
        programId: programId,
      }),
      Transactions.createMetadataAccount({
        payer: operator.identity.publicKey,
        mint: mintKeypair.publicKey,
        address: metadataAddress,
        name: currency.name,
        symbol: currency.symbol,
        uri: jsonURI,
        collectionMint: params.collectionMint,
        isCollection: params.nft === "collection",
      }),
      Transactions.createTokenAccount({
        payer: operator.identity.publicKey,
        address: tokenAddress,
        mint: mintKeypair.publicKey,
        programId: programId,
      }),
    ];
    if (amount && amount.qty > 0n) {
      transactions.push(
        Transactions.mintTokens({
          mint: mintKeypair.publicKey,
          dstToken: tokenAddress,
          mintAuthority: operator.identity.publicKey,
          qty: amount.qty,
          programId: programId,
        })
      );
    }
    if (params.nft) {
      transactions.push(
        Transactions.createNFTMasterEdition({
          payer: operator.identity.publicKey,
          mint: mintKeypair.publicKey,
          metadata: metadataAddress,
          programId: programId,
        })
      );
    }
    if (params.collectionMint) {
      transactions.push(
        Transactions.verifyNFTCollectionItem({
          payer: operator.identity.publicKey,
          metadata: metadataAddress,
          collectionMint: params.collectionMint,
        })
      );
    }

    const result = await operator.execute(...transactions);
    const account = await this.init(
      operator,
      tokenAddress,
      result.accounts.get(tokenAddress.toString()),
      result.accounts.get(mintKeypair.publicKey.toString()),
      result.accounts.get(metadataAddress.toString())
    );
    return [account, result];
  }

  public static async init(
    operator: Operator,
    address: PublicKey,
    accountInfo?: AccountInfo<Buffer> | null,
    mintInfo?: AccountInfo<Buffer> | null,
    metadataInfo?: AccountInfo<Buffer> | null
  ): Promise<TokenAccount> {
    const existent = await this._checkExistent<TokenAccount>(
      operator,
      address,
      accountInfo
    );
    if (existent) return existent;

    accountInfo ??= await operator.connection.getAccountInfo(address);
    if (!accountInfo) throw new Error(`Account not found ${address}`);

    const lamports = accountInfo.lamports;
    const programId = accountInfo.owner;
    const account = SPLToken.unpackAccount(address, accountInfo, programId);
    const owner = account.owner;
    const mint = await MintAccount.init(
      operator,
      account.mint,
      mintInfo,
      metadataInfo
    );
    const balance = mint.metadata.currency.amountFromQty(account.amount);

    const result = new this({
      operator,
      address,
      lamports,
      programId,
      owner,
      balance,
      mint,
    });
    return await result._init();
  }

  public toString(): string {
    return `<${this.address}: { balance: ${this.balance}, rent: ${this.rent} }>`;
  }

  /**
   * Checks whether the {@link operator}
   * can perform {@link send} action on the account.
   */
  public get canSend(): boolean {
    return this._getCached("canSend", () =>
      Boolean(this.operator.identity?.publicKey.equals(this.owner))
    );
  }

  /**
   * Sends tokens from the account to given recipients.
   */
  public async send(
    ...instructions: SendInstruction[]
  ): Promise<TransactionResult> {
    if (!this.canSend) {
      throw new Error(`Account ${this} does not belong to the operator`);
    }
    const create: TransactionBlock[] = [];
    const send: TransactionBlock[] = [];

    const addresses: PublicKey[] = [];
    for (let { to, value, qty, memo } of instructions) {
      const amount = new Amount(this.balance.currency, value, qty);
      if (amount.qty <= 0) throw new Error(`Cannot send ${amount}`);

      const address = PDA.token(this.mint.address, to, this.programId);
      addresses.push(address);
      send.push(
        Transactions.sendTokens({
          src: this.owner,
          srcToken: this.address,
          dstToken: address,
          programId: this.programId,
          qty: amount.qty,
        })
      );
      if (memo) send.push(Transactions.addMemo({ memo }));
    }

    const accountsInfo = await this.operator.connection.getMultipleAccountsInfo(
      addresses
    );
    addresses.forEach((address, index) => {
      if (!accountsInfo[index]) {
        create.push(
          Transactions.createTokenAccount({
            payer: this.operator.identity!.publicKey,
            owner: instructions[index].to,
            address: address,
            mint: this.mint.address,
            programId: this.programId,
          })
        );
      }
    });

    return await this.operator.execute(...create, ...send);
  }

  /**
   * Checks whether the {@link operator}
   * can perform {@link burnNFT} action on the account.
   */
  public get canBurnNFT(): boolean {
    return this._getCached("canBurnNFT", () =>
      Boolean(this.mint.isNFT && this.balance.qty === 1n)
    );
  }

  /**
   * Burns token
   */
  public async burnNFT(): Promise<TransactionResult> {
    if (!this.canBurnNFT) {
      throw new Error(`Cannot burn account ${this} as NFT`);
    }
    const result = await this.operator.execute(
      Transactions.burnNFT({
        owner: this.owner,
        mint: this.mint.address,
        address: this.address,
        metadata: this.metadata.address,
        collectionMint: this.metadata.collectionMintAddress,
        programId: this.programId,
      })
    );
    await this.deactivate();
    return result;
  }

  protected async _refresh(accountInfo: AccountInfo<Buffer>): Promise<void> {
    super._refresh(accountInfo);
    const account = SPLToken.unpackAccount(
      this.address,
      accountInfo,
      this.programId
    );
    this.balance.qty = account.amount;
  }
}
