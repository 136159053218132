const postToSlack = async (msg) => {
  let url = `${process.env.REACT_APP_HNGR_API}/fcsc/post-to-slack`
  let headers = { 
   "Content-Type": "application/json"
  }   
  let params = {msg:msg}
  let resp = await fetch(url, {
    method: "post",
    headers: headers,
    body: JSON.stringify(params)
  }) 
}

export default postToSlack
