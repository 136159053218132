import { useEffect, useState } from "react";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react'
import { useHistory } from "react-router-dom"
import "../css/landing.css"

import postToSlack from "../postToSlack"

const Landing = props => {

  const history = useHistory()
  const { publicKey, disconnect } = useWallet()
  const [ clicked, setClicked ] = useState(false)


  useEffect(() => {
    if(publicKey){
      if(!clicked){
        console.log("did not click.... disconnecting")
        disconnect()
      } else {
        console.log("clicked.... changing page")
        history.push("/create/create")
        postToSlack(`${publicKey.toString()} connected their wallet on merchant side!`)
      }
    }
  }, [publicKey]);


  return (
    <div className='landing-container landing-container-black'>
      <div className='landing-container-center-content'>
          <>  
          <div className='landing-center-header-text'>
            Seal a tpNFT
          </div>

          <WalletMultiButton>
            <div 
              onClick={()=>setClicked(true)}
              className='landing-connect-button-container'
              style={{
                cursor:'pointer',
                marginTop:'32px',
                marginBottom:'32px'
              }}
            >
              <div className='connect-button-text' >CONNECT WALLET</div>
            </div>
          </WalletMultiButton>

          <div className='landing-subtext'>
            Connect your Solana wallet and create a tpNFT that contains a sealed message.
          </div>
        </>
      </div>
    </div>
  )
}

export default Landing 
