import { useEffect, useState } from "react";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react'
import { useHistory } from "react-router-dom"
import {
  Connection,
  PublicKey,
  Transaction,
  clusterApiUrl,
  SystemProgram,
  sendAndConfirmTransaction,
} from "@solana/web3.js";
import Header from "../Header/Header"
import MintingModal from "./MintingModal"
import ErrorModal from "./ErrorModal"
import SuccessModal from "./SuccessModal"
import * as Sentry from "@sentry/browser"
import "./Create.css"

import postToSlack from "../postToSlack"

const Create = props => {

  const history = useHistory()
  const { publicKey, sendTransaction } = useWallet()
  const [secret, setSecret] = useState('')
  const [mint, setMint] = useState(null)
  const [metadata, setMetadata] = useState(null)
  const [error, setError] = useState(false)
  const [minting, setMinting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [signature, setSignature] = useState(null)
  const [number, setNumber] = useState('') 

  useEffect(() => {
    if(!publicKey){
      history.replace("/create/landing")
    }
  }, [publicKey]);

  useEffect(() => {
    const number = Math.floor(Math.random() * 3000)
    setNumber(number)
  }, []);



  const mintNFT = async () => {
    if(!publicKey){
      alert("Please connect your wallet!")
    }
    setError(false)
    setMinting(true)
    postToSlack(`${publicKey.toString()} started minting`)
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/tamperproofMint`
    console.log("process", process.env)
    let headers = {
     "Content-Type": "application/json"
    }
    let params = {
      secret:secret,
      number:number,
      signature:signature,
      wallet:publicKey.toString()
    }
    try{
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      resp = await resp.json()
      if(resp.success){
        postToSlack(`${publicKey.toString()} succesfully minted`)
        setMint(resp.mint)
        setMetadata(resp.metadata)
        setMinting(false)
        setSuccess(true)
      } else {
        postToSlack(`${publicKey.toString()} had captured error minting`)
        setError(true)
      }
    } catch (err) {
      postToSlack(`${publicKey.toString()} had unexepcted error minting`)
      console.log(err)
      setError(true)
      return
    }
  }

  const chargeSOL = async() => {
    postToSlack(`${publicKey.toString} is gonna pay 0.02 SOL`)
    const LAMPORTS_PER_SOL = 1000000000
    const treasury = new PublicKey(process.env.REACT_APP_TREASURY)
    const connection = new Connection(process.env.REACT_APP_RPC)
    const sol = 0.02
    let lamports = sol * LAMPORTS_PER_SOL
    let transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: treasury,
        lamports: lamports,
      })
    );
    transaction.feePayer = publicKey;
    const anyTransaction = transaction;
    anyTransaction.recentBlockhash = (
      await connection.getRecentBlockhash()
    ).blockhash;
    try{
      let signature = await sendTransaction(transaction, connection)
    } catch (err){
      Sentry.captureException(err);
      /*
      if(err.message && err.message.indexOf('has not been authorized') > -1){
        alert('There is an issue with phantom. Please refresh the page.')
      }*/  
      Sentry.configureScope((scope) => {
        Sentry.captureMessage("Possible bug with paying for mint")
      }) 
      alert('There may be an issue with Phantom. Please refresh the page.')
      return
    }
    postToSlack(`${publicKey.toString()} paid 0.02 SOL`)
    setSignature(signature)
    mintNFT()
  }


  return (
    <>
      {success && (
        <SuccessModal 
          number={number} 
          metadata={metadata} 
          mint={mint}
        />
      )}
      {minting && <MintingModal number={number} />}
      {error && <ErrorModal mint={()=> mintNFT()} />}
      <div className='header-page header-page-black header-page-centered'>
        <Header create/>
        <div className='create-center'>
          <div className='cube-box'>
            <img
              alt='cube'
              src='https://cdn.hngr.co/tamperproof/solcube.gif'
              className='cube-box-img'
            />
          </div>
          <div className='create-center-right'>
            <div className='create-center-right-nft-title'>
              TAMPERPROOF #{number} 
            </div>

            <div className='write-secret-message'>
              Write a secret message to be sealed 🤐
            </div>

            <div className='secret-message-box'>
              <input 
                value={secret}
                onChange={(e)=>setSecret(e.target.value)}
                placeholder='Enter your secret message'
                maxlength="30"
                className='secret-message-box-input'
              />
            </div>
            <div className='max-chars'>
              Max 30 characters
            </div>
          </div>
        </div>

        <div 
          className={`mint-button ${secret ? '' : 'mint-button-disabled'}`}
          onClick={()=>chargeSOL()}
        >
          <div>0.02 SOL</div>
          <div style={{margin:'0px 32px'}}>|</div>
          <div>Mint > </div>
        </div>
      </div>
    </>
  )
}

export default Create 
