import "../Create/modal.css"
import ModalNFT from "../Create/ModalNFT"
import Zebra from "./Zebra"

const DecryptedModal = ( {secret, close, token} ) => {

  const copy = () => {
    navigator.clipboard.writeText(secret)
  }

  return (
    <div className='modal-background'>
      <div className='modal-container modal-container-white'>
        <div className='modal-top-row'>
          <div className='modal-top-row-left'>
            <div 
              className='modal-top-row-left-top-text'
              style={{color:'#43BA7D'}}
            >
              NFT UNSEALED
            </div>
            <div 
              style={{color:'#43BA7D'}}
              className='modal-top-row-left-bottom-text'
            >
              &lt;REVEAL ENABLED&gt;
            </div>
          </div>
          <img
            alt='spinner'
            src='https://cdn.hngr.co/tamperproof/keywhite.gif'
            className='modal-top-row-image'
          />
        </div> { /*modal-top-row*/ }
        <ModalNFT
          img={token ? token.metadata._json.image : ''}
          name={token ? token.metadata._json.name : ''}
        />

        <Zebra 
          style={{marginTop:'32px', marginBottom:'32px'}} 
          decrypted
          secret={secret}
        />


        <div className='modal-button-row'>
          <div onClick={copy} className='copy-code-modal-button'>
            COPY CODE
          </div>

          <div onClick={close} className='view-nft-modal-button'>
            <div>VIEW NFT</div>
          </div>
        </div>

      </div>{ /*modal-container*/ }
    </div>
  )

}

export default DecryptedModal 
