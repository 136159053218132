import { useEffect, useState } from "react";
import { useWallet } from '@solana/wallet-adapter-react'
import { useHistory } from "react-router-dom"
import {
  setConfig,
  Wallet,
  KeypairIdentity,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
} from "@captainxyz/solana-core";
import { 
  isTamperproofHolder,
  isTamperproofOracle
} from "@captainxyz/tamperproof";
import Header from "../Header/Header"
import Loading from "../Loading/Loading"
import "./NFTs.css"
require('@solana/wallet-adapter-react-ui/styles.css');


const HolderNFTs = props => {

  const [loaded, setLoaded] = useState(false)
  const [canUnsealList, setCanUnsealList] = useState([])
  const [canDecryptList, setCanDecryptList] = useState([])

  const history = useHistory()
  const adapter = useWallet()

  useEffect(() => {
    if(adapter.publicKey){
      getTokenAccounts()
    }
  }, []);

  useEffect(() => {
    if(adapter.publicKey){
      getTokenAccounts()
    }   
  }, [adapter]);


  useEffect(() => {
    if(!adapter.publicKey) history.replace("/holder/landing")
  }, [adapter.publicKey]);

  const getTokenAccounts = async () => {
    console.log("get token accounts")
    const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
    const operator = new Operator("mainnet-beta", walletAdapterIdentity);
    setConfig(
      'mainnet-beta', 
      { rpcEndpoint: 'https://shy-icy-water.solana-mainnet.quiknode.pro/b2e2d1cb7ef4ff5db6dd353035e14739a5904ffb/'} 
    );
    let tokens = await TokenAccount.find(operator)
    let unsealList = []
    let decryptList = []
    for (let token of tokens) {
      if (isTamperproofHolder(token)) {
        if (token.canRequestUnsealing && token.balance._qty) {
          unsealList.push(token)
        }
        if (token.canDecrypt && token.balance._qty) {
          decryptList.push(token)
        }
      }
    }
    setCanDecryptList(decryptList)
    setCanUnsealList(unsealList)
    if(!decryptList.length && !unsealList.length){
      history.push("/holder/no-nfts")
    } else {
      setLoaded(true)
    }
  }

  const unseal = async (token) => {
    let a = await token.requestUnsealing();
    console.log("unsealed", a)

    let url = `${process.env.REACT_APP_EXPRESS_API}/api/tamperproofRequestReveal`
    console.log("process", process.env)
    let headers = {
     "Content-Type": "application/json"
    }
    let params = {
      wallet:adapter.publicKey.toString()
    }
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
    console.log("process", process.env)
    resp = await resp.json()
  }



  const decrypt = async (token) => {
    let a = await token.decrypt();
  }

  if(!loaded){
    return (
      <div className='landing-container landing-container-white'>
        <div className='landing-container-center-content'>
          <>
            <img 
              src='https://cdn.hngr.co/tamperproof/tamperproof_logo_black.png'
              alt='logo' 
              className='landing-logo'
            />
            <div className='landing-connect-button-container'>
              <img 
                src='https://cdn.hngr.co/tamperproof/landingspinner.gif' 
                className='landing-spinner'
                alt='spinner'
              />
            </div>
            <div style={{marginTop:'32px'}} className='loading-subtext'>
              Scanning your wallet for Tamperproof NFTs
            </div>
            <div style={{marginTop:'8px'}} className='loading-subtext'>
               If you have a large NFT collection, this will take a moment
            </div>

          </>
        </div>
      </div>

    )
  }

  return (
    <>  
      <div className='header-page header-page-white '>
        <Header holder/>
        <>
          <div className='nfts-detected-title'>
            TAMPERPROOF NFTs DETECTED
          </div>

          <div className='holder-nfts-list'>
            {canUnsealList.map(nft => (
              <NFT
                key={nft.mint.address.toString()}
                nft={nft}
                sealed={true}
                history={history}
              />
            ))}
            
            {canDecryptList.map(nft => (
              <NFT
                key={nft.mint.address.toString()}
                nft={nft}
                unsealed={true}
                history={history}
                onClick={()=>decrypt(nft)}
              />
            ))}
          </div>

        </>
      </div>

    </>

  )
}


//<h5>{nft.metadata._json.name}</h5>

const NFT = ({nft, sealed, unsealed, history})  => (
  <div 
    className='holder-nft-container'
    onClick={()=>{
      let mint = nft.mint.address.toString()
      history.push(`/holder/NFT/${mint}`)
    }}
  >
    <img
      alt='img'
      className='holder-nft-image'
      src={nft.metadata._json.image}
    />
    <div className='holder-nft-bottom'>
      {sealed && (
        <div className='holder-nft-sealed-tag holder-nft-tag'>
          <div>SEALED</div>
        </div>
      )}
      
      {unsealed && (
        <div className='holder-nft-unsealed-tag holder-nft-tag'>
          <div>UNSEALED</div>
        </div>
      )}
      
      <div className='holder-nft-title'>
        {nft.metadata._json.name}
      </div>

    </div>
  </div>
)

export default HolderNFTs 
