import { PublicKey, SystemProgram, Keypair } from "@solana/web3.js";
import * as SPLMemo from "@solana/spl-memo";
import * as SPLToken from "@solana/spl-token";
import * as MPLTokenMetadata from "@metaplex-foundation/mpl-token-metadata";

import { PDA } from "./pda";
import { TransactionBlock } from "./operator";

export namespace Transactions {
  export function addMemo(params: { memo: string }): TransactionBlock {
    return {
      instructions: [SPLMemo.createMemoInstruction(params.memo)],
    };
  }

  export function sendSOLs(params: {
    src: PublicKey;
    dst: PublicKey;
    qty: bigint;
  }): TransactionBlock {
    return {
      instructions: [
        SystemProgram.transfer({
          fromPubkey: params.src,
          toPubkey: params.dst,
          lamports: params.qty,
        }),
      ],
    };
  }

  export function sendTokens(params: {
    src: PublicKey;
    srcToken: PublicKey;
    dstToken: PublicKey;
    programId?: PublicKey;
    qty: bigint;
  }): TransactionBlock {
    return {
      instructions: [
        SPLToken.createTransferInstruction(
          params.srcToken, // Source token account
          params.dstToken, // Destination token account
          params.src, //      Owner of the source account
          params.qty, //      Amount to transfer
          [], //              Signing accounts if owner is a multisig
          params.programId // Token program id
        ),
      ],
    };
  }

  export function mintTokens(params: {
    mint: PublicKey;
    dstToken: PublicKey;
    mintAuthority: PublicKey;
    qty: bigint;
    programId?: PublicKey;
  }): TransactionBlock {
    return {
      instructions: [
        SPLToken.createMintToInstruction(
          params.mint, //          Mint address
          params.dstToken, //      Destination token account address
          params.mintAuthority, // The mint authority
          params.qty, //           Amount to mint
          [], //                   Signing accounts if authority is a multisig
          params.programId //      Token program id
        ),
      ],
    };
  }

  export function createTokenAccount(params: {
    payer: PublicKey;
    owner?: PublicKey;
    address: PublicKey;
    mint: PublicKey;
    programId?: PublicKey;
  }): TransactionBlock {
    params.owner ??= params.payer;
    return {
      allocate: [params.address],
      instructions: [
        SPLToken.createAssociatedTokenAccountInstruction(
          params.payer, //                 Payer
          params.address, //               Token account address
          params.owner, //                 Owner of the account
          params.mint, //                  Mint address
          params.programId //              Token program id
          // ASSOCIATED_TOKEN_PROGRAM_ID   Associated token program id
        ),
      ],
    };
  }

  export function createMintAccount(params: {
    payer: PublicKey;
    keypair?: Keypair;
    lamports: number;
    decimals: number;
    mintAuthority?: PublicKey;
    freezeAuthority?: PublicKey;
    programId?: PublicKey;
  }): TransactionBlock {
    params.keypair ??= Keypair.generate();
    params.mintAuthority ??= params.payer;
    params.freezeAuthority ??= params.payer;
    params.programId ??= SPLToken.TOKEN_PROGRAM_ID;
    return {
      signers: [params.keypair],
      allocate: [params.keypair.publicKey],
      instructions: [
        SystemProgram.createAccount({
          fromPubkey: params.payer,
          newAccountPubkey: params.keypair.publicKey,
          space: SPLToken.MINT_SIZE,
          lamports: params.lamports,
          programId: params.programId,
        }),
        SPLToken.createInitializeMint2Instruction(
          params.keypair.publicKey, //  New mint address
          params.decimals, //           Number of decimals in amounts
          params.mintAuthority, //      Minting authority
          params.freezeAuthority, //    Freezing authority
          params.programId //           Token program id
        ),
      ],
    };
  }

  export function createMetadataAccount(params: {
    payer: PublicKey;
    mint: PublicKey;
    address?: PublicKey;
    mintAuthority?: PublicKey;
    updateAuthority?: PublicKey;
    collectionMint?: PublicKey;
    isCollection?: boolean;
    name: string;
    symbol: string;
    uri: string;
  }): TransactionBlock {
    params.address ??= PDA.tokenMetadata(params.mint);
    params.mintAuthority ??= params.payer;
    params.updateAuthority ??= params.payer;
    return {
      allocate: [params.address],
      instructions: [
        MPLTokenMetadata.createCreateMetadataAccountV3Instruction(
          {
            payer: params.payer,
            mint: params.mint,
            metadata: params.address,
            mintAuthority: params.mintAuthority,
            updateAuthority: params.updateAuthority,
          },
          {
            createMetadataAccountArgsV3: {
              data: {
                name: params.name,
                symbol: params.symbol,
                uri: params.uri,
                sellerFeeBasisPoints: 0,
                creators: [
                  {
                    address: params.mintAuthority,
                    verified: true,
                    share: 100,
                  },
                ],
                collection: params.collectionMint
                  ? { key: params.collectionMint, verified: false }
                  : null,
                uses: null,
              },
              isMutable: true,
              collectionDetails: params.isCollection
                ? { __kind: "V1" as const, size: 0 }
                : null,
            },
          }
        ),
      ],
    };
  }

  export function updateMetadataAccount(params: {
    address: PublicKey;
    updateAuthority: PublicKey;
    data: MPLTokenMetadata.DataV2;
  }): TransactionBlock {
    return {
      instructions: [
        MPLTokenMetadata.createUpdateMetadataAccountV2Instruction(
          {
            metadata: params.address,
            updateAuthority: params.updateAuthority,
          },
          {
            updateMetadataAccountArgsV2: {
              data: params.data,
              updateAuthority: params.updateAuthority,
              isMutable: true,
              primarySaleHappened: null,
            },
          }
        ),
      ],
    };
  }

  export function createNFTMasterEdition(params: {
    payer: PublicKey;
    mint: PublicKey;
    address?: PublicKey;
    metadata?: PublicKey;
    mintAuthority?: PublicKey;
    updateAuthority?: PublicKey;
    programId?: PublicKey;
  }): TransactionBlock {
    params.address ??= PDA.masterEdition(params.mint);
    params.metadata ??= PDA.tokenMetadata(params.mint);
    params.mintAuthority ??= params.payer;
    params.updateAuthority ??= params.payer;
    params.programId ??= SPLToken.TOKEN_PROGRAM_ID;
    return {
      allocate: [params.address],
      instructions: [
        MPLTokenMetadata.createCreateMasterEditionV3Instruction(
          {
            edition: params.address,
            mint: params.mint,
            mintAuthority: params.mintAuthority,
            updateAuthority: params.updateAuthority,
            payer: params.payer,
            metadata: params.metadata,
            tokenProgram: params.programId,
          },
          { createMasterEditionArgs: { maxSupply: 0 } }
        ),
      ],
    };
  }

  export function verifyNFTCollectionItem(params: {
    payer: PublicKey;
    metadata: PublicKey;
    collectionAuthority?: PublicKey;
    collectionMint: PublicKey;
    collectionMetadata?: PublicKey;
    collectionMasterEdition?: PublicKey;
  }): TransactionBlock {
    params.collectionAuthority ??= params.payer;
    params.collectionMetadata ??= PDA.tokenMetadata(params.collectionMint);
    params.collectionMasterEdition ??= PDA.masterEdition(params.collectionMint);
    return {
      instructions: [
        MPLTokenMetadata.createVerifySizedCollectionItemInstruction({
          metadata: params.metadata,
          collectionAuthority: params.collectionAuthority,
          payer: params.payer,
          collectionMint: params.collectionMint,
          collection: params.collectionMetadata,
          collectionMasterEditionAccount: params.collectionMasterEdition,
        }),
      ],
    };
  }

  export function unverifyNFTCollectionItem(params: {
    payer: PublicKey;
    metadata: PublicKey;
    collectionAuthority?: PublicKey;
    collectionMint: PublicKey;
    collectionMetadata?: PublicKey;
    collectionMasterEdition?: PublicKey;
  }): TransactionBlock {
    params.collectionAuthority ??= params.payer;
    params.collectionMetadata ??= PDA.tokenMetadata(params.collectionMint);
    params.collectionMasterEdition ??= PDA.masterEdition(params.collectionMint);
    return {
      instructions: [
        MPLTokenMetadata.createUnverifySizedCollectionItemInstruction({
          metadata: params.metadata,
          collectionAuthority: params.collectionAuthority,
          payer: params.payer,
          collectionMint: params.collectionMint,
          collection: params.collectionMetadata,
          collectionMasterEditionAccount: params.collectionMasterEdition,
        }),
      ],
    };
  }

  export function burnNFT(params: {
    owner: PublicKey;
    mint: PublicKey;
    address?: PublicKey;
    metadata?: PublicKey;
    masterEdition?: PublicKey;
    collectionMint?: PublicKey | null;
    programId?: PublicKey;
  }): TransactionBlock {
    params.programId ??= SPLToken.TOKEN_PROGRAM_ID;
    params.address ??= PDA.token(params.mint, params.owner, params.programId);
    params.metadata ??= PDA.tokenMetadata(params.mint);
    params.masterEdition ??= PDA.masterEdition(params.mint);
    return {
      instructions: [
        MPLTokenMetadata.createBurnNftInstruction({
          metadata: params.metadata,
          owner: params.owner,
          mint: params.mint,
          tokenAccount: params.address,
          splTokenProgram: params.programId,
          masterEditionAccount: params.masterEdition,
          collectionMetadata: params.collectionMint
            ? PDA.tokenMetadata(params.collectionMint)
            : undefined,
        }),
      ],
    };
  }
}
