import { useEffect, useState } from "react";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react'
import {
  setConfig,
  Wallet,
  KeypairIdentity,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
} from "@captainxyz/solana-core";

import { 
  isTamperproofHolder,
  isTamperproofOracle
} from "@captainxyz/tamperproof";
import "./Home.css"
require('@solana/wallet-adapter-react-ui/styles.css');


const Home = props => {

  const [canUnsealList, setCanUnsealList] = useState([])
  const [canDecryptList, setCanDecryptList] = useState([])

  const adapter = useWallet()

  useEffect(() => {
    if(adapter.publicKey){
      getTokenAccounts()
    }   
  }, [adapter]);

  const getTokenAccounts = async () => {
    const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
    const operator = new Operator("mainnet-beta", walletAdapterIdentity);
    setConfig(
      'mainnet-beta', 
      { rpcEndpoint: 'https://shy-icy-water.solana-mainnet.quiknode.pro/b2e2d1cb7ef4ff5db6dd353035e14739a5904ffb/'} 
    );
    let tokens = await TokenAccount.find(operator)
    let unsealList = []
    let decryptList = []
    for (let token of tokens) {
      if (isTamperproofHolder(token)) {
        if (token.canRequestUnsealing) {
          console.log("can request", token)
          unsealList.push(token)
        }
        if (token.canDecrypt) {
          console.log("can decrypt", token)
          decryptList.push(token)
          // token.decrypt()
        }
      } else if (isTamperproofOracle(token)){
        alert('is oracle')

      } else{
        console.log("not tp", token)
      }
    }
    setCanDecryptList(decryptList)
    setCanUnsealList(unsealList)
    window.tokens = tokens
    window.decrypt = decryptList
  }

  const unseal = async (token) => {
    token.requestUnsealing();
    //let msg = new TextEncoder().encode('Hello, world!')
    //adapter.signMessage(msg)
  }


  const decrypt = async (token) => {
    let a = await token.decrypt();
    console.log("decrypt", a)
    //let msg = new TextEncoder().encode('Hello, world!')
    //adapter.signMessage(msg)
  }


  return (
    <div>
      <h3> Welcome to Bacon Wrapped Demo! </h3>
      <WalletMultiButton />
      <br/><br/>
      <h3> Can DEcrypt </h3>
      {canDecryptList.map(token => (
        <NFT
          key={token.mint.address.toString()}
          action='decrypt'
          onClick={() => decrypt(token)}
          nft={token}
        />
      ))}
      <br/><br/>

      <h3> Can Unseal </h3>
      {canUnsealList.map(token => (
        <NFT
          key={token.mint.address.toString()}
          action='unseal'
          onClick={() => unseal(token)}
          nft={token}
        />
      ))}
      <br/><br/><br/><br/>

    </div>
  )
}

const NFT = ({nft, action, onClick})  => (
  <div className='nft-container'>
    <h5>{nft.metadata._json.name}</h5>
    <br/><br/>
    <a 
      href={`https://solana.fm/address/${nft.mint.address.toString()}?cluster=mainnet-solanafmbeta`}
      target='_blank'
    >
      SolanaFM
    </a>
    <br/><br/>
    <img 
      className='nft-image'
      src={nft.metadata._json.image} 
    />
    <br/><br/>
    {action === 'unseal' && (
      <button
        onClick={onClick}
      >
        Unseal
      </button>
    )}

    {action === 'decrypt' && (
      <button
        onClick={onClick}
      >
        Decrypt
      </button>
    )}

  </div>
)

export default Home
