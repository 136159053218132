import { PublicKey, AccountInfo, SystemProgram } from "@solana/web3.js";

import { Operator, TransactionBlock, TransactionResult } from "../operator";
import { Transactions } from "../transactions";
import { SOL, Amount } from "../currency";
import { BaseAccount, SendInstruction } from "./base";

/**
 * @group Accounts
 */
export class SolanaAccount extends BaseAccount {
  public readonly balance: Amount;

  protected constructor(params: {
    operator: Operator;
    address: PublicKey;
    lamports: number | bigint;
  }) {
    super(params);
    this.balance = this.rent;
  }

  /**
   * @param address
   * If omitted, operator.{@link Operator.publicKey} will be used.
   */
  static async init(
    operator: Operator,
    address?: PublicKey,
    accountInfo?: AccountInfo<Buffer> | null
  ): Promise<SolanaAccount> {
    if (!address) {
      if (!operator.identity) throw new Error("Undefined account address");
      address = operator.identity.publicKey;
    }
    const existent = await this._checkExistent<SolanaAccount>(
      operator,
      address,
      accountInfo
    );
    if (existent) return existent;

    accountInfo ??= await operator.connection.getAccountInfo(address);
    if (accountInfo && !accountInfo.owner.equals(SystemProgram.programId)) {
      throw new Error(`Account ${address} was not created by system progam`);
    }
    const lamports = accountInfo ? accountInfo.lamports : 0;

    const result = new this({
      operator,
      address,
      lamports,
    });
    return await result._init();
  }

  public toString(): string {
    return `<${this.address}: { balance: ${this.balance} }>`;
  }

  /**
   * Requestes to airdrop number of SOLs on the account.
   */
  public async airdrop(value: number): Promise<void> {
    const signature = await this.operator.connection.requestAirdrop(
      this.address,
      Number(SOL.valueToQty(value))
    );
    await this.operator.confirm(signature);
  }

  /**
   * Checks whether the {@link operator}
   * can perform {@link send} action on the account.
   */
  public get canSend(): boolean {
    return this._getCached("canSend", () =>
      Boolean(this.operator.identity?.publicKey.equals(this.address))
    );
  }

  /**
   * Sends SOLs from the account to given recipients.
   */
  public async send(
    ...instructions: SendInstruction[]
  ): Promise<TransactionResult> {
    const blocks: TransactionBlock[] = [];
    if (!this.canSend) {
      throw new Error(`Account ${this} does not belong to the operator`);
    }
    for (let { to, value, qty, memo } of instructions) {
      const amount = new Amount(SOL, value, qty);
      if (amount.qty <= 0) throw new Error(`Cannot send ${amount}`);
      blocks.push(
        Transactions.sendSOLs({
          src: this.address,
          dst: to,
          qty: amount.qty,
        })
      );
      if (memo) blocks.push(Transactions.addMemo({ memo }));
    }
    return await this.operator.execute(...blocks);
  }

  protected async _refresh(accountInfo: AccountInfo<Buffer>): Promise<void> {
    super._refresh(accountInfo);
    this.balance.qty = accountInfo.lamports;
  }
}
