import React from 'react';
import { render } from "react-dom"
import { BrowserRouter, Route } from "react-router-dom"
import WalletContext from "./contexts/WalletContext"
import "./css/global.css"

// routes
import Home from "./Home/Home"
import LandingCreate from "./Create/Landing"
import Create from "./Create/Create"
import CreateSuccess from "./Create/Success"

import LandingHolder from "./Holder/Landing"
import HolderNFTs from "./Holder/NFTs"
import HolderNFT from "./Holder/NFT"
import HolderNoNFTs from "./Holder/NoNFTs"
import LogRocket from 'logrocket';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://eae8194c42a947c08c8a7c10604c333c@o133761.ingest.sentry.io/4505054852546560",
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});


LogRocket.init(process.env.REACT_APP_LOGROCKET)


class App extends React.Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%"
        }}
      >
        <Route path="/" exact component={LandingHolder} />

        { /* create routes */ }
        <Route path="/create/landing" exact component={LandingCreate} />
        <Route path="/create/create" exact component={Create} />
        <Route path="/create/success" exact component={CreateSuccess} />

        { /* holder routes */ }
        <Route path="/holder/landing" exact component={LandingHolder} />
        <Route path="/holder/NFTs" exact component={HolderNFTs} />
        <Route path="/holder/no-nfts" exact component={HolderNoNFTs} />
        <Route path="/holder/NFT/:mint" exact component={HolderNFT} />

      </div>
    )
  }
}


render(
  <BrowserRouter>
    <WalletContext>
      <App />
    </WalletContext>
  </BrowserRouter>,
  document.getElementById("root")
)
