import "../Create/modal.css"
import ModalNFT from "../Create/ModalNFT"

const ErrorModal = ( {close, token, unseal} ) => {

  return (
    <div className='modal-background'>
      <div className='modal-container modal-container-white'>
        <div className='modal-top-row'>
          <div className='modal-top-row-left'>
            <div 
              className='modal-top-row-left-top-text'
              style={{color:'#FF4949'}}
            >
              ERROR
            </div>
            <div 
              style={{color:'#FF4949'}}
              className='modal-top-row-left-bottom-text'
            >
            SOMETHING WENT WRONG
            </div>
          </div>
          <img
            alt='spinner'
            src='https://cdn.hngr.co/tamperproof/error.png'
            className='modal-top-row-image'
          />
        </div> { /*modal-top-row*/ }
        <ModalNFT
          img={token?.metadata._json.image}
          name={token?.metadata._json.name}
        />

        <div 
          style={{marginTop:'28px'}}
          className='sign-reveal-button'
          onClick={()=>unseal()}
        >
          <div>RETRY UNSEAL</div>
        </div>

      </div>{ /*modal-container*/ }
    </div>
  )

}

export default ErrorModal 
