 import "./modal.css"
import ModalNFT from "./ModalNFT"
import { useHistory } from 'react-router-dom';

const SuccessModal = ( props ) => {

  const history = useHistory()

  const viewNFT = () => {
    history.push({
      pathname:"/create/success",
      state:{
        metadata:props.metadata,
        mint:props.mint
      }
    })
  }

  return (
    <div className='modal-background'>
      <div className='modal-container'>
        <div className='modal-top-row'>
          <div 
            className='modal-top-row-left'
            style={{color:'#43BA7D'}}
          >
            <div className='modal-top-row-left-top-text'>
              NFT MINTED
            </div>
            <div className='modal-top-row-left-bottom-text'>
              &lt;SECRET SEALED&gt;
            </div>
          </div>
          <img
            alt='key'
            src='https://cdn.hngr.co/tamperproof/keyblack.gif'
            className='modal-top-row-image'
          />
        </div> { /*modal-top-row*/ }
        <ModalNFT
          img='https://cdn.hngr.co/tamperproof/solcube.gif'
          name={`TAMPERPROOF #${props.number}`}
        />
        <div 
          onClick={()=>viewNFT()}
          className='view-nft-button'
        >
          <div>VIEW NFT</div>
        </div>
      </div>{ /*modal-container*/ }
    </div>
  )

}

export default SuccessModal
