import {
  Keypair,
  Connection,
  VersionedTransaction,
  PublicKey,
} from "@solana/web3.js";
import {
  MessageSignerWalletAdapter,
  SendTransactionOptions,
} from "@solana/wallet-adapter-base";
import * as ed25519 from "@noble/ed25519";

export interface Identity {
  get publicKey(): PublicKey;
  sign(message: string | Uint8Array): Promise<Uint8Array>;
  sendTransaction(
    transaction: VersionedTransaction,
    connection: Connection,
    options?: SendTransactionOptions
  ): Promise<string>;
}

export class KeypairIdentity implements Identity {
  public readonly keypair: Keypair;
  public readonly publicKey: PublicKey;

  public constructor(keypair: Keypair) {
    this.keypair = keypair;
    this.publicKey = keypair.publicKey;
  }

  public static generate(): KeypairIdentity {
    return new this(Keypair.generate());
  }

  public async sign(message: string | Uint8Array): Promise<Uint8Array> {
    if (typeof message == "string") message = Buffer.from(message, "utf-8");
    return await ed25519.sign(message, this.keypair.secretKey.slice(0, 32));
  }

  public async sendTransaction(
    transaction: VersionedTransaction,
    connection: Connection,
    options?: SendTransactionOptions
  ): Promise<string> {
    transaction.sign([this.keypair, ...(options?.signers ?? [])]);
    return await connection.sendTransaction(transaction, options);
  }
}

export class WalletAdapterIdentity implements Identity {
  public readonly adapter: MessageSignerWalletAdapter;
  public readonly publicKey: PublicKey;

  public constructor(adapter: MessageSignerWalletAdapter) {
    if (!adapter.publicKey) throw new Error("Wallet is not connected");
    this.adapter = adapter;
    this.publicKey = adapter.publicKey;
  }

  public async sign(message: string | Uint8Array): Promise<Uint8Array> {
    if (typeof message == "string") message = Buffer.from(message, "utf-8");
    return await this.adapter.signMessage(message);
  }

  public async sendTransaction(
    transaction: VersionedTransaction,
    connection: Connection,
    options?: SendTransactionOptions
  ): Promise<string> {
    return await this.adapter.sendTransaction(transaction, connection, options);
  }
}
