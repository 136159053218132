import "../Create/modal.css"
import ModalNFT from "../Create/ModalNFT"
import Zebra from "./Zebra"

const UnsealedModal = ( props ) => {

  return (
    <div className='modal-background'>
      <div className='modal-container modal-container-white'>
        <div className='modal-top-row'>
          <div className='modal-top-row-left'>
            <div 
              className='modal-top-row-left-top-text'
              style={{color:'#43BA7D'}}
            >
              NFT UNSEALED
            </div>
            <div 
              style={{color:'#43BA7D'}}
              className='modal-top-row-left-bottom-text'
            >
              &lt;REVEAL ENABLED&gt;
            </div>
          </div>
          <img
            alt='spinner'
            src='https://cdn.hngr.co/tamperproof/keywhite.gif'
            className='modal-top-row-image'
          />
        </div> { /*modal-top-row*/ }
        <ModalNFT
          img={props.token?.metadata._json.image}
          name={props.token?.metadata._json.name}
        />

        <Zebra unsealed style={{marginTop:'32px', marginBottom:'32px'}} />

        <div 
          onClick={() => props.decrypt()}
          className='sign-reveal-button'
        >
          <div>SIGN & REVEAL CODE</div>
        </div>

      </div>{ /*modal-container*/ }
    </div>
  )

}

export default UnsealedModal 
