import "./Loading.css"

const Loading = (props) => (
  <div className='loading-container'>
    <img
      src='https://cdn.hngr.co/tamperproof/landingspinner.gif'
      alt='spinner'
    />
  </div>
)

export default Loading
