import "../Create/modal.css"
import "./holderModals.css"
import ModalNFT from "../Create/ModalNFT"

const UnsealModal = ( props ) => {

  return (
    <div className='modal-background'>
      <div className='modal-container modal-container-white'>
        <div className='unseal-warning'>WARNING</div>
        <div className='unseal-warning-subtext'>
          Are you sure you want to unseal:
        </div>

        <ModalNFT
          img={props.token?.metadata?._json.image}
          name={props.token?.metadata?._json.name}
        />

        <div className='unseal-warning-subtext'>
          Unsealing a Tamperproof NFT is permanent and cannot be undone. 
          <br/><br/>
          Others will be able to see that this NFT has been unsealed.
        </div>

        <div 
          className='unseal-action-modal-button'
          onClick={() => props.unseal() }
        >
          <div> YES, UNSEAL </div>
        </div>

        <div 
          className='keep-secret'
          onClick={() => props.close()}
        >
          No, keep this a secret
        </div>

      </div>{ /*modal-container*/ }
    </div>
  )

}

  export default UnsealModal 
