import "./modal.css"
import ModalNFT from "./ModalNFT"

const MintingModal = ( props ) => {

  return (
    <div className='modal-background'>
      <div className='modal-container'>
        <div className='modal-top-row'>
          <div className='modal-top-row-left'>
            <div className='modal-top-row-left-top-text'>
              MINTING...
            </div>
            <div className='modal-top-row-left-bottom-text'>
              &lt;SEALING SECRET&gt;
            </div>
          </div>
          <img
            alt='spinner'
            src='https://cdn.hngr.co/tamperproof/landingspinner.gif'
            className='modal-top-row-image'
          />
        </div> { /*modal-top-row*/ }
        <ModalNFT
          img='https://cdn.hngr.co/tamperproof/solcube.gif'
          name={`TAMPERPROOF #${props.number}`}
        />
        <div className='modal-subtext'>
          This may take a few minutes...
        </div>
      </div>{ /*modal-container*/ }
    </div>
  )

}

export default MintingModal
