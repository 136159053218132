const Zebra = ( {style, sealed, unsealed, decrypted, secret} ) => {

  const getBottomBackgroundColor = () => {
    if(sealed) return '#43BA7D'
    if(unsealed || decrypted) return '#FF4949'
  }

  return (
    <div className='nft-view-sealed-blurb-box' style={style} >
      <div className='nft-view-sealed-blurb-box-top'>
        {sealed && <> CODE SEALED BY TAMPERPROOF </>}
        {unsealed && <> SIGN TO REVEAL CODE </>}
        {decrypted && <ScratchOff secret={secret} />}
      </div>
      
      <div 
        className='nft-view-sealed-blurb-box-bottom'
        style={{
          background:getBottomBackgroundColor()
        }}
      >
        <div 
          className='bottom-blurb-box-inner'
        >
          {sealed && <> SEALED </>}
          {unsealed && <> UNSEALED </>}
        </div>
      </div>
    </div>
  )
}

const ScratchOff = ({secret}) => (
  <div className='scratch-off-container'>
    <img 
      src='https://cdn.hngr.co/tamperproof/scratchbig.png'
      className='scratch-off-img'
      alt='white'
    />
    <div 
      className='scratch-off-secret'
      dangerouslySetInnerHTML={{ __html: secret }} 
    >
    </div>
  </div>
)

export default Zebra
