import { useEffect, useState } from "react";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react'
import { useHistory, useParams } from "react-router-dom"
import {
  setConfig,
  Wallet,
  KeypairIdentity,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
  PDA
} from "@captainxyz/solana-core";

import { 
  isTamperproofHolder,
  isTamperproofOracle
} from "@captainxyz/tamperproof";

import { PublicKey } from "@solana/web3.js"
import Header from "../Header/Header"
import Loading from "../Loading/Loading"
import ErrorModal from "./ErrorModal";
import UnsealModal from "./UnsealModal";
import UnsealedModal from "./UnsealedModal";
import UnsealingModal from "./UnsealingModal";
import DecryptedModal from "./DecryptedModal"
import Zebra from "./Zebra"
import "./NFTs.css"
import "../Create/Create.css"

import postToSlack from "../postToSlack"
import * as Sentry from "@sentry/browser";


const NFT = (props) => {

  const [loaded, setLoaded] = useState(false)
  const [adaterLoaded, setAdapterLoaded] = useState(false)
  const [secret, setSecret] = useState(null)
  const [token, setToken] = useState(false)
  const [sealed, setSealed] = useState(null)
  const [unsealed, setUnsealed] = useState(null)
  const [showUnsealModal, setShowUnsealModal] = useState(false)
  const [showUnsealingModal, setShowUnsealingModal] = useState(false)
  const [showUnsealedModal, setShowUnsealedModal] = useState(false)
  const [showDecryptedModal, setShowDecryptedModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const { mint } = useParams()

  const history = useHistory()
  const adapter = useWallet()

  useEffect(() => {
    if(adapter.publicKey){
      //getTokenAccounts()
      getToken()
    }
  }, [adapter]);


  useEffect(() => {
    if(!adapter.publicKey) history.replace("/holder/landing")
  }, [adapter.publicKey]);

  useEffect(() => {
    if(adapter.signMessage) {
      console.log("can sign", adapter.signMessage)
      setAdapterLoaded(true) 
      if(adapter.publicKey){
        postToSlack(`${adapter.publicKey.toString()} is viewing NFT ${mint}`)
      }
    }
  }, [adapter.signMessage]);
  
  

  const getToken = async () => {
    const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
    const operator = new Operator("mainnet-beta", walletAdapterIdentity);
    setConfig(
      'mainnet-beta',
      { rpcEndpoint: 'https://shy-icy-water.solana-mainnet.quiknode.pro/b2e2d1cb7ef4ff5db6dd353035e14739a5904ffb/'}
    );
 
    let tokenAddress = PDA.token(
      new PublicKey(mint),
      adapter.publicKey
    )
    let token = await TokenAccount.init(operator, tokenAddress)
    if (token.canRequestUnsealing) {
      if(token.mint.address.toString() == mint){
        setToken(token)
        setSealed(true)
        return
      }
    }
    if (token.canDecrypt) {
       if(token.mint.address.toString() == mint){
        setToken(token)
        setUnsealed(true)
        return
      }
    }
  }

  /*
  const getTokenAccounts = async () => {
    const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
    const operator = new Operator("mainnet-beta", walletAdapterIdentity);
    setConfig(
      'mainnet-beta',
      { rpcEndpoint: 'https://shy-icy-water.solana-mainnet.quiknode.pro/b2e2d1cb7ef4ff5db6dd353035e14739a5904ffb/'}
    );
    let tokens = await TokenAccount.find(operator)
    let unsealList = []
    let decryptList = []
    
    for (let token of tokens) {
      if (isTamperproofHolder(token)) {
        if (token.canRequestUnsealing) {
          if(token.mint.address.toString() == mint){
            setToken(token)
            setSealed(true)
            break
          }
        }
        if (token.canDecrypt) {
           if(token.mint.address.toString() == mint){
            setToken(token)
            setUnsealed(true)
            break
          }
        }
      }
    }
  }
  */
  
  const unseal = async () => {
    setShowErrorModal(false)
    setShowUnsealModal(false)
    setShowUnsealingModal(true)
    postToSlack(`${adapter.publicKey.toString()} is requesting unseal for ${mint}`)

    // if we somehow got here with an already unsealed one
    if(token.canDecrypt){
      setShowUnsealingModal(false)
      setShowUnsealedModal(true)
      return
    }

    let a 
    try{
      a = await token.requestUnsealing();
    } catch(err){
      Sentry.captureException(err);
      if(err.message && err.message.indexOf('has not been authorized') > -1){
        alert('There may be an issue with Phantom. Please refresh the page.')
      }
      Sentry.configureScope((scope) => {
        Sentry.captureMessage("Bug with unsealing")
      })
      setShowUnsealingModal(false)
      setShowErrorModal(true)
      return
    }
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/tamperproofRequestReveal`
    console.log("process", process.env)
    let headers = { 
     "Content-Type": "application/json"
    }   
    let params = { 
      wallet:adapter.publicKey.toString(),
      sig:a.signature,
      mint:token.mint.address.toString(),
      oracle_id:token.metadata._json.oracle_id
    }   
    try{
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })  
      console.log("process", process.env)
      resp = await resp.json()
      if(resp.success){
        setShowUnsealingModal(false)
        setShowUnsealedModal(true)
        postToSlack(`${adapter.publicKey.toString()} succesfully unsealed for ${mint}`)
      } else {
        postToSlack(`${adapter.publicKey.toString()} captured unsealed error for ${mint}`)
        setShowUnsealingModal(false)
        setShowErrorModal(true)
      }
    } catch (err){
      console.log(err)
      postToSlack(`${adapter.publicKey.toString()} unkown unsealed error for ${mint}`)
      setShowUnsealingModal(false)
      setShowErrorModal(true)
    }
  }

  const decryptFromModal = async () => { 
    postToSlack(`${adapter.publicKey.toString()} decrypted from modal for ${mint}`)
    try {
      let secret = await token.decrypt()
      setSecret(secret)
      setShowDecryptedModal(true)
      setShowUnsealedModal(false)
    } catch (err) {
      Sentry.captureException(err);
      if(err.message && err.message.indexOf('has not been authorized') > -1){
        Sentry.configureScope((scope) => {
          Sentry.captureMessage("Bug with decrypting from modal")
        })
        alert('There may be an issue with Phantom. Please refresh the page.')
      }
 
    }
  }

  const decrypt = async () => { 
    let secret
    try {
      secret = await token.decrypt()
    } catch (err){
      Sentry.configureScope((scope) => {
        Sentry.captureMessage("Bug with decrypting from nft page")
      })
      Sentry.captureException(err);
      if(err.message && err.message.indexOf('has not been authorized') > -1){
        alert('There may be an issue with Phantom. Please refresh the page.')
      }
      return
    }
    setSecret(secret)
    postToSlack(`${adapter.publicKey.toString()} decrypted from nft page for ${mint}`)
  }

  const copyCode = () => {
    navigator.clipboard.writeText(secret)
  }

  const renderDescription = () => {
    let description = token.metadata._json.description
    if(!description) return null
    let lines = description.split("\n")
    return (
      <>
        {lines.map(line => (
          <>
            {line && (
              <div className='nft-view-subtext'>
                {line}
              </div>
            )}
          </>
        ))}
      </>
    )
  }

  return (
    <>
      {showDecryptedModal && (
        <DecryptedModal 
          secret={secret}
          token={token}
          close={()=>{
            setSealed(false)
            setUnsealed(true)
            setShowDecryptedModal(false)
          }}
        />
      )}
      {showUnsealModal && (
        <UnsealModal 
          close={()=>setShowUnsealModal(false)} 
          token={token}
          unseal={()=>unseal()}
        />
      )}
      {showUnsealedModal && (
        <UnsealedModal 
          token={token}
          decrypt={()=>decryptFromModal()}
        />
      )}
      
      {showErrorModal && (
        <ErrorModal 
          token={token}
          unseal={()=>unseal()}
          close={()=>setShowErrorModal(false)}
        />
      )}

      {showUnsealingModal && <UnsealingModal token={token}/>} 
      <div className='header-page header-page-white header-page-centered'>
        <Header holder/>
      
        {(token && (sealed || unsealed) && adaterLoaded ) ? ( 
          <div className='create-center'>
            <img
              alt='cube'
              src={token.metadata._json.image}
              className='view-nft-image'
            />
            <div className='create-center-right'>
              {sealed && (
                <div className='nft-view-sealed-tag'>
                  SEALED
                </div>
              )}

              {unsealed && (
                <div className='nft-view-unsealed-tag'>
                  UNSEALED
                </div>
              )}
              
              <div className='nft-view-title'>
                {token.metadata._json.name}
                {unsealed && <span>&nbsp;(UNSEALED)</span>}
              </div>
              

              {renderDescription()}

              {sealed && <Zebra sealed />}
              {unsealed && !secret && <Zebra unsealed />}
              {secret && <Zebra decrypted secret={secret} />}

              {sealed && (
                <div 
                  className='nft-view-unseal-button'
                  onClick={()=>setShowUnsealModal(true)}
                >
                  <div> &lt; UNSEAL &gt;</div>
                </div>
              )}

              {unsealed && !secret && (
                <div 
                  onClick={() =>decrypt()}
                  className='sign-reveal-button'
                >
                  <div>SIGN & REVEAL CODE</div>
                </div>
              )}

              {secret && (
                <div 
                  className='nft-copy-code-btn'
                  onClick={() => copyCode()}
                >
                  <div>COPY CODE</div>
                </div>
              )}

            </div> {/* center-right*/}
          </div> 
        ) : (
          <Loading />
        )}



      </div> {/* header-page */ }

    </>
    
  )
}

export default NFT
